@media screen and (max-width: 1200px) {
  .class-classList.container {
    max-width: 90%;
  }
}

.class-column {
  display: grid;
  grid-template-columns: 0.01fr 1fr 0.01fr 1fr 0.01fr 1fr 0.01fr 1fr 0.01fr 1fr;
  gap: 6px;
}

@media screen and (max-width: 1550px) {
  .class-column {
    grid-template-columns: 0.01fr 1fr 0.01fr 1fr 0.01fr 1fr 0.01fr 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .class-column {
    grid-template-columns: 0.01fr 1fr 0.01fr 1fr;
  }
}

@media screen and (max-width: 790px) {
  .class-column {
    grid-template-columns: 0.01fr 1fr;
  }
}

@media screen and (max-width: 576px) {
  .class-column {
    grid-template-columns: 0.01fr 1fr;
  }
}

.student-section-block {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e6;
  width: 350px;
  margin: 15px 0px;
}

.student-section-block td {
  border: none !important;
  padding: 0px;
}

.student-section-block th {
  border-bottom: 1px solid #dee2e6;
}

.class-classList.container h2 {
  color: black;
}


.class-classList.container p {
  font-family: 'Arial Narrow';
  font-size: 18px;
  color: black;
}

.class-classList.container .class-classList-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 30px;
}

.class-classList.container .class-classList-header h2.siteName {
  margin-top: 0px;
}

.class-classList.container select {
  font-size: 21px;
  color: #004d63;
  border: none;
  border-bottom: solid lightgrey 1px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800
}

.class-classList.container select.instructor-select {
  font-weight: 500;
  font-family: 'Arial Narrow';
  font-size: 18px;
  color: black;
  border: none 

}

@media screen and (max-width: 1200px) {
  .class-classList.container .class-classList-header {
    margin-top: 0px;
    align-items: center;
  }

  .class-classList.container select {
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
  }

}

@media screen and (max-width: 790px) {
  .class-classList.container .class-classList-header {
    flex-wrap: wrap;
  }
}

select.instructor-select {
  font-family: 'Arial Narrow';
  font-size: 18px;
  color: black;
  padding: 0px 15px;
  margin: 0;
  font-weight: 500;
  line-height: 25px;
  width: 100%;
}

.camp-details-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 50%;
}

@media screen and (max-with: 576px) {
  .camp-details-block {
    flex-direction: column;
  }
}

.new-student {
  background-color: #c4e4ef !important;
}

.student-details-block p {
  padding: 0px 15px;
  margin: 0;
  font-weight: 500;
  line-height: 25px;
}

.student-details-block p.student-name {
  font-weight: 600;
  font-size: 24px;
  padding: 0px 5px 10px 10px;
  margin-bottom: 7px;
  flex-basis: 70%;
}

@media screen and (max-width: 1680px) {
  .student-details-block p.student-name {
    padding: 0px 5px 5px 6px;
    margin-bottom: 0px;
    flex-basis: 70%;
  }

  .student-details-block p {
    padding: 0px 6px;
  }
}

.student-details-block .student-block-top-section div {
  flex-basis: 15%;
}

.student-details-block .student-block-top-section {
  border-bottom: solid 1px darkgrey;
}

.student-details-block .student-block-middle-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
}

.student-block-right-section {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.student-details-block hr+p {
  font-weight: 600;
  font-size: 27px;
  padding: 0px 15px 10px 15px;
}

.student-details-block:active p .student-icon {
  background-color: #2d9846;

}

.student-details-block .student-block-top-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.student-details-block .student-block-top-section .AM-icon,
.student-details-block .student-block-top-section .PM-icon,
.student-details-block .student-block-top-section .AD-icon {
  height: 25px;
  width: 21px;
  background-color: #989898;
  border-radius: 50%;
  float: right;
  font-size: 15px;
  padding-left: 4px;
  padding-right: 2px;
  color: white;
  font-weight: 100;
  margin: 0px 10px 0 0;
}


.student-details-block .student-block-top-section .AD-icon {
  background-color: #5b5b5b;
}

.student-details-block .student-block-middle-section .EC-icon {
  background-color: #b84bc8;
  font-size: 20px;
  padding: 5px;
  color: white;
  font-weight: 100;
}

.EC-icon-container {
  margin-top: 0.5em !important
}

.class-classList-level .class-volume {
  color: #4c689f;
}


.class-classList-level .class-volume.class-has-error {
  color: #d95c34;
}

.class-has-error .student-section-block {
  background-color: #d95c34 !important;
}


@media screen and (max-width: 1600px) {
  .class-classList-level {
    grid-template-columns: 33% 33% 33%;
  }
}

@media screen and (max-width: 1200px) {
  .class-classList-level {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 640px) {
  .class-classList-level {
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 480px) {
  .class-classList.container select {
    font-size: 20px;
  }
}

.alert-container {
  background-color: antiquewhite;
  padding: 0.5rem 0rem 0.5rem 2rem;
  margin-bottom: 2em;
}

.alert-container h4 {
  color: #c55f3b;
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 5px;
}

.class-classList.container .alert-container p {
  font-size: 19px;
  font-family: 'Arial Narrow';
  margin-top: 0;
}

.student-column1.student-col-2134, 
.student-column2.student-col-2134, 
.student-column3.student-col-2134, 
.student-column4.student-col-2134,
.student-column1.student-col-2,
.student-column2.student-col-2,
.student-column3.student-col-2,
.student-column4.student-col-2,
.student-column1.student-col-1001,
.student-column2.student-col-1001,
.student-column3.student-col-1001,
.student-column4.student-col-1001,
.student-column5.student-col-1001,
.student-column6.student-col-1001,
.student-column1.student-col-1002,
.student-column2.student-col-1002,
.student-column3.student-col-1002,
.student-column4.student-col-1002,
.student-column5.student-col-1002,
.student-column6.student-col-1002,
.student-column1.student-col-1003,
.student-column2.student-col-1003,
.student-column3.student-col-1003,
.student-column4.student-col-1003,
.student-column5.student-col-1003,
.student-column6.student-col-1003,
.student-column1.program-Swim,
.student-column2.program-Swim,
.student-column3.program-Swim,
.student-column4.program-Swim,
.student-column5.program-Swim
{
  background-color: #eff4f7 !important;
}

.student-column-am.student-column1,
.student-column-pm.student-column1,
.student-column-am.student-column2,
.student-column-pm.student-column2,
.student-column-am.student-column3,
.student-column-pm.student-column3,
.student-column-am.student-column4,
.student-column-pm.student-column4,
.student-column-am.student-column5,
.student-column-pm.student-column5 {
  background-color: #eff4f7 !important;
}

.student-column-ac,
.student-column-bc {
  background-color: #eff4f7 !important;
}

.student-column-ac.student-column1,
.student-column-bc.student-column1,
.student-column-ac.student-column2,
.student-column-bc.student-column2,
.student-column-ac.student-column3,
.student-column-bc.student-column3,
.student-column-ac.student-column4,
.student-column-bc.student-column4,
.student-column-ac.student-column5,
.student-column-bc.student-column5,
.student-column-ac.student-column6,
.student-column-bc.student-column6,
.student-column-ac.student-column7,
.student-column-bc.student-column7,
.student-column-ac.student-column8,
.student-column-bc.student-column8 {
  background-color: #eff4f7 !important;
}


.classList-button {
  display: flex;
  justify-content: flex-end;
}

.instructor-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 13px 8px 13px 0px;

}

.camp-header-block {
  display: grid;
  grid-template-columns: 1fr;
  padding: 15px 0px;
}

.save-class-button-container {
  justify-content: space-between;
  display:flex;
}

/*
f25f41 Peach (Higher Warning)
ffac52 Yellow (Medium Warning)
 */

/* Validation Component */
.validation-component {
  margin: 2em 2em 2em 0em;
}

.validation-component h3 {
  font-family: 'Arial Narrow';
  font-weight: 700;
}

.validation-component p {
  font-family: 'Arial Narrow'
}

.validation-component h3.validation-error-title {
  color: #bf623f;
}

.validation-component .validation-component-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.validation-component .validation-component-box h3 {
  flex: 0 0 50%;
  color: #bf623f;
}

.validation-component .validation-component-box span {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.validation-component .validation-component-box span p {
  flex: 0 0 50%;
}

.validation-component .validation-component-box span input {
  flex: 0 0 50%;
  padding: 16px;
  border: none;
  border-bottom: 1px solid #808080;
}

.validation-component-box p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.line {
  height: 30px;
  width: 2px;
  background-color: #98989873;
  border-radius: 11%;
  float: right;
  transform: skewX(46deg);
  transform-origin: 80% 102%;
}

.line-small {
  height: 19px;
  width: 2px;
  background-color: #98989873;
  border-radius: 11%;
  float: right;
  transform: skewX(46deg);
  transform-origin: 80% 102%;
}

.line-extra-small {
  height: 8px;
  width: 2px;
  background-color: #98989873;
  border-radius: 11%;
  float: right;
  transform: skewX(46deg);
  transform-origin: 80% 102%;
}

.button.show-hide-button {
  font-size: 12px;
  padding: 5px;
  margin: 0em 1em 0em 0em;
}

.add-column-component {
  background-color: aliceblue;
  text-align: center;
}

.add-column-component button {
  display: contents;
}

.delete-column-component button {
  display: contents;
}

.delete-column-component {
  display: flex;
  justify-content: flex-end;
}

p.max-instructor-0 {
  color: #f25f41;
}

.alert-component-buttons {
  display: flex;
}

.class-rule-display {
  margin-top: 25px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 25px;
}

.class-rule-display h2 {
  font-size: 29px;
}

.class-rule-display h3 {
  font-size: 20px;
}

.class-rule-display h4 {
  margin: 0;
  padding: 0;
}

.class-rule-display p {
  margin-top: 0px;
  margin-bottom: 20px;

}

.class-rule-nav {
  display: flex;
}

.class-rule-nav a {
  margin-left: 0px;
}

.instructor-rules {
  padding-inline-start: 15px;
}

.class-rule-header-container {
  border-bottom: solid 1px #7fa3c6;
  margin-bottom: 15px
}

.class-rule-header-container h3 {
  margin-bottom: 0px;
}

.aux-instructor-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
@media screen and (max-width: 1024px){
  .aux-instructor-section {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 640px){
  .aux-instructor-section {
    grid-template-columns: 1fr;
  }
}
.addMorerrinstructors {
  float:right;
}
.aux-instructor-button {
  border: #7fa3c6 1px solid;
  background: transparent;
  color: #7fa3c6;
  font-size: 26px;
  width: 35px;
  text-align: center;
}

.class-classList.container .aux-instructor-section select.instructor-select
 {
  border-bottom: 1px solid black;
  padding: 14px;
  margin-bottom: 5px;
}
