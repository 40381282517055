/************************************ Site & class Content **********************************************/
.site-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

h2.site-name {
    font-size: 21px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.site-container h2.site-name {
    flex: 0 0 80%;
}

.site-container button {
    background-color: transparent;
}

.button.isOpen img {
    transform: scaley(-1);
    -webkit-transform: scaleY(-1);
}

@media screen and (max-width: 675px) {


    .icon.actionable.details {
        vertical-align: -webkit-baseline-middle;
    }
}

.icon {
    width: 25px;
    filter: invert(72%) sepia(42%) saturate(4800%) hue-rotate(348deg) brightness(92%) contrast(87%);
}

.icon.actionable {
    cursor: pointer;
}

#close-classList-button {
    float: right;
    background-color: white;
    color: #004d63;
    border: none;

}

.nested-table td {
    padding: 0.55rem 3rem;
}

@media screen and (max-width: 1200px) {
    .nested-table td {
        padding: 0.1rem 1rem;
    }
}


.nested-table table th {
    font-weight: 600;
    padding: 0.75rem;
    vertical-align: top;
    border-top: none;
    color: #888;
}

@media screen and (max-width: 992px) {


    .button.primary-button {
        font-size: 14px;
    }
}

@media screen and (max-width: 576px) {
    h2.site-name {
        font-size: 16px;
    }
    .site-container h2.site-name {
        flex: 1 0 70%;
    }
}

/******************************************************* Modal Component ****************************************************/
.modal-component {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: flex-start;
    background: rgb(1 38 49 / 45%);
    overflow: auto !important;
    z-index: 12;
}

.modal-content {
    background: white;
    margin: 0 auto;
    border: 1px solid #ddd;
    box-shadow: 0 19px 38px rgb(0 0 0 / 20%), 0 15px 12px rgb(0 0 0 / 20%);
    position: relative;
    padding: 30px !important;
    margin-top: 100px;
    margin-bottom: 100px;
}

.close-modal {
    float: right;
    padding-bottom: 15px;
    cursor: pointer;
}

/* Instructor Input Form in Modal */

.instructor-input-form {
    margin: 20px;
}

.instructor-input-form form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
}

.instructor-input-form .instructor-input-form-section {
    padding-top: 10px;
}

@media screen and (max-width: 790px) {
    .instructor-input-form .instructor-input-form-section {
        border-bottom: solid lightgray 1px;
        padding-bottom: 0.5em;
    }
}

.instructor-input-form label {
    font-weight: bold;
    font-family: 'Arial Narrow';

}

.instructor-input-form input[type=text],
.instructor-input-form select {
    margin-right: 15px;
    margin-left: 5px;
}

.instructor-input-form input[type=radio] {
    margin-right: 15px;

}

.instructor-button {
    display: flex;
    justify-content: space-between;
}

.instructor-input-form-section.missing-content {
    background-color: #f25f41;
}

.instructor-schedule {
    display: none !important
}


/* Camp Docs Modal */
.camp-docs-toggle-titles {
    font-size: 20px;
    font-weight: 600;
    width: 500px;
    margin-bottom: 15px;
}

h2.camp-docs {
    font-size: 30px;
    margin-bottom: 0px;
    color: #4d6ca4;
    border-bottom: solid 1px #7fa3c6;
    padding-bottom: 20px;
}

.camp-docs-title-container {
    border-bottom: solid 1px #7fa3c6;
}

.camp-docs-container {
    margin-top: 25px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 25px;
}

.camp-docs-title-container {
    display: flex;
}

.camp-docs-title-container button {
    background: transparent;
}

.camp-docs-nested-title-container {
    display: flex;
}

.camp-docs-second-nested-title-container {
    display: flex;
}

.camp-docs-second-nested-title-container h4 {
    margin-top: 0;
    font-family: 'Arial Narrow';
    color: #586ba2;
    font-weight: 300;
}

.camp-docs-second-nested-title-container button {
    background: transparent;
}

.camp-docs-nested-title-container button {
    background: transparent;
    filter: brightness(0.5);
    padding: 0;
}

.camp-docs-nested-title-container h4 {
    margin: 0;
}

.nested-container {
    margin-top: 2em;
    margin-bottom: 2em;
}

.camp-docs-second-nested-title-container {
    text-decoration: underline;
    margin-left: 1em;
    margin-bottom: 0px;
    color: #004d63;
    cursor: pointer;
}

.camp-docs-second-nested-title-container h4 {
    margin-bottom: 0px;
}


.nested-container li {
    list-style: none;
    margin-top: 0px;
}

.nested-container ul {
    list-style: none;
    margin-top: 0px;
    padding-inline-start: 0px;
    margin-inline-start: 1em;
}

.nested-container a {
    color: #586ba2;
}

.year-dropdown-toggle{
    font-size: 19px;
    display: inline-flex;
    background-color: #f0f0f0;
    color: #004d63;
    font-family: 'Arial Narrow';
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

}