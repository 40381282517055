@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;600;700&display=swap');

body {
    background: #004d63;
    font-family: 'Arial Narrow';
    overflow-x: hidden;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
}

a {
    color: #ff9626;
    transition: all .3s ease;
}

a:hover {
    text-decoration: none;
    color: #dd8322;
    cursor: pointer;
}

a.disable {
    pointer-events: none;
}

button {
    cursor: pointer;
}
button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}


.button.primary-button {
    font-size: 16px;
}

.button {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: none;
    padding: 8px 20px;
    margin: 0 5px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
}

.primary-button {
    background: #ffac52;
    color: #004d63 !important;
}

.button.ghost {
    border: 1px solid #dd8322;
    color: #dd8322 !important;
    background: transparent;
}


table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

table th {
    font-weight: 600;
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table td {
    vertical-align: top;
    font-weight: 300;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    color: #000000;
    text-align: -webkit-center;
}

@media screen and (max-width: 675px) {
    #siteListContent tr:first-of-type td:first-of-type {
        padding-top: 1.5rem;
    }
    #siteListContent  td:last-of-type {
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 1.5rem;
    }
    #siteListContent  td {
        padding: 0.15rem;
        padding-top: 0.25rem;
        border-top: none;
    }

    #siteListContent td, th {
        display: flex;
        width: 100%;
        gap: 2rem;
    }
    table th {
        display: none;
    }
    td:before {
        content: attr(data-label);
        float: left;
        font-weight: 600;
        flex: 0 0 30%;
      }

      .nested-table td {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      
      }
      #classContentTable td {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      #close-classList-button {
        margin-left: auto;
      }
      #classContentTable tr {
        border-bottom: 1px solid #dee2e6;
      }

}

#open-classLists-button.button.primary-button {
    float: none;
}

.button-align-right {
    float: right;
}

.button.secondary-button {
    background: #7fa3c6;
    color: #ffff !important;
    font-size: 16px;
}

@media screen and (max-width: 675px) {
    .button.primary-button, .button.secondary-button {
        font-size: 14px;
    }
    
}

.button.tertiary-button {
    background: transparent;
    text-decoration: underline #7fa3c6;
    text-underline-offset: 8px;
    font-weight: 500;
    padding-left: 0;
}

button:hover {
    transition: all .3s ease;
    opacity: .7;
}

button:visited,
button:focus {
    opacity: 1;
}

.desktop-only {
    display: grid!important;
}
@media screen and (max-width:1024px){
    .desktop-only {
        display: none!important;
    }

}

.mobile-only {
    display: none!important;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;

}

@media screen and (max-width:675px){
    .button-container {
        flex-direction: column;
    }
}
@media screen and (max-width:1024px){
    .mobile-only {
        display: block!important;
    }

}
.ph-header.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 1em;
}

@media screen and (max-width: 1550px) {
    .container {
        padding: 0 15px;
        max-width: 75%;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 90%;
    }
}

@media screen and (max-width: 992px) {

    table td,
    table th {
        font-size: 14px !important;
    }

    .button.primary-button {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 720px;
    }
   
}

@media screen and (max-width: 576px) {

    .program-location.container {
        max-width: 540px;
        margin: auto;
        width: 92%;
    }
}

.container {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: static;
    max-width: 80%;
}

/******************************* Main Header  ************************************/
.ph-header .ph-logo img {
    width: 220px;
}

.ph-header a {
    color: #ffffff;
}

@media screen and (max-width: 576px) {
    .ph-header .ph-logo img {
        width: 160px;
    }
}

/****************************** Main Container  *******************************/
.dashboard {
    background: #ffffff;
    padding-bottom: 8em;
}

.dashboard h2 {
    color: #004d63;
}

.loading.print-loader {
    min-height: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}

.loading {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loading img {
    animation: rotating 1s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading.file-upload-loading {
    min-height: 0px;
}

/******************************************* Primary Navigation  ********************************/
.primary-nav {
    border-bottom: solid lightgray 1px;
}

.primary-nav .welcome {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.primary-nav .welcome>* {
    margin-right: 2.5em;
}

.primary-nav .welcome p {
    font-size: 21px;
    font-weight: 800;
    color: black;
    flex: 0 0 25%;
}

@media  screen and (max-width:768px) {
    .primary-nav .welcome {
        flex-direction: column;
        align-items: start;
    }
}

.welcome .main-nav-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 1em 0em 1em 0em;
    grid-gap: 0.75em;
}

@media screen and (max-width: 675px) {
    .welcome .main-nav-links {
        grid-template-columns: 1fr 1fr;
    }
    .primary-nav .welcome {
        flex-direction: column;
    }
}



.primary-nav .welcome a .ph-profile-icon {
    width: 37px;
}

.welcome a {
    color: #004d63;
}

.welcome a:hover {
    color: #dd8322;
}

.welcome a:hover img.ph-profile-icon {
    filter: invert(72%) sepia(96%) saturate(1017%) hue-rotate(339deg) brightness(95%) contrast(90%);
}

.welcome a .welcome-link-text {
    vertical-align: super;
    font-size: 19px;
}

.welcome .welcome-text {
    font-weight: 600;
}

@media screen and (max-width: 576px) {
    .primary-nav .welcome a .ph-profile-icon {
        width: 25px;
    }
    .welcome a .welcome-link-text {
        font-size: 14px;
    }
    .primary-nav .welcome>* {
        margin-right: 0;
    }
    .container {
        width: 100%;
        padding: 0;
        margin: 0 auto;
        position: static;
        max-width: 90%;
    }
}

.main-nav-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}

.main-nav-buttons button.active {
    background: #ffac52;
    color:#004d63;
}

/* Login */

.login,
.login .third-party {
    display: flex;
    justify-content: center;
}

.login {
    width: 100%;
    height: 70vh;
    align-items: center;
    padding: 0;
    max-width: 100%;
    background: #f4f4f4;
}

@media screen and (max-width:1024px){
    .login {
        width: 80%;
    }
}

.auth-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

a {
    text-decoration: none;
}

.auth-button {
    margin-bottom: 16px;
    padding: 12px 16px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    font-size: 16px;
    line-height: 1.5em;
    transition: all .3s ease;
    transform: scale(1);
    cursor: pointer;
}

.auth-button.google {
    background: #e6e6e6;
    color: rgba(0, 0, 0, 0.54);
}

.auth-button.facebook {
    color: white;
    background: #1877f2;
}

.auth-button.microsoft {
    background: #1DA1F2;
    color: #fff;
}



.auth-button:hover {
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 12%), 0 3px 3px 0 rgb(0 0 0 / 24%);
}

.auth-button.google:hover {
    background: #e6e6e66b;
}

.auth-button.microsoft:hover {
    background: #1da1f2bf;
    color: #fff;
}

.auth-button .auth-logo {
    display: inline-block;
    margin-right: 16px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    vertical-align: top;
}

.auth-button.disabled,
.auth-button:disabled {
    background: #EEEEEE;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.login-container {
    display: flex;
    box-shadow: 0 4px 2px -3px #888888;
    background: #ffffff;
    padding: 50px;
    width: 700px;
}

.login-container div {
    flex: 0 1 80%;
}

@media screen and (max-width: 576px) {
    .login-container div {
        flex: 0 1 90%;
    }
    
}

.login .demo-login h3 {
    font-size: 30px;
    color: #3B6CA4;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    margin-bottom: 0;
}

.login .demo-login h4 {
    font-family: 'Arial Narrow';
    font-weight: 500;
    margin-top: 0;
}

.login-form {
    display: flex;
}

.logout-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 21px;
    font-weight: 800;
    font-family: 'Arial Narrow';
}

@media screen and (max-width: 576px) {
    .login-container {
        flex-direction: column;
        padding: 25px;
    }

    .login .demo-login h3 {
        text-align: center;
    }
}

@media screen and (max-width: 360px) {
    .auth-button {
        padding: 8px 7px;
        font-size: 14px;
    }

    .auth-button .auth-logo {
        margin-right: 9px;

    }
}

/* saving loader */

.saving-icon img {
    animation: rotating 1s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.multi-program-print-container {
    display:flex;
    flex-wrap: wrap;
}

.multi-program-print-container .loading.print-loader{
    justify-content: flex-start;
}

.notes-on-checkin{
    display:flex;
    justify-content: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.notes-on-checkin textarea{
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: 'Arial Narrow';
}