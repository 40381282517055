.top-section-print {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
.class-print-section h2 {
    font-size: 20px;
    margin: 0;
}

.classlist-print-section h2 {
    font-size: 20px;
    margin: 0;
}

.print-class-details h3 {
    margin: 0
}

.class-classList-level-print .student-section-block-6 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: auto;
}

.class-classList-level-print .student-section-block-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: auto;
}

.class-classList-level-print .student-section-block .student-details-block {
    width: auto;
    margin: 10px;
    background-color: #dee2e6;
    padding: 10px 0px 10px 0px
}

.Print {
    aspect-ratio: 8.5/11;
    background: #ffffff;
}

.Print header {
    background: #0567a3;
    background: linear-gradient(90deg, #0567a3, #4ac7e9);
    margin-top: 0em;
    max-width: 100%;
    padding: 20px;
}

.Print img {
    filter: grayscale(1)
}


.class-classList-level-print .student-section-block .student-details-block p:first-child {
    font-size: 16px;
    padding: 0px 5px 10px 5px;
    border: none;
}

.class-classList-level-print .student-section-block .student-details-block p {
    font-size: 12px;
    padding: 0px 5px 0px 5px;
}

.class-classList-level-print .student-section-block .student-details-block h3 {
    color: black;
    font-size: 12px;
    padding: 0px 5px 0px 5px;
}

.class-classList-level-print .instructor-block {
    display: block;
    width: auto;
}

.class-classList-level-print {
    aspect-ratio: 8.5/11;
}

@media all {
    .page-break {
        display: none;
    }
}

@media screen {
    .Print {
        display: none;
    }
}

.empty-student-print-row {
    height: 20px
}

.empty-student-print-row:last-of-type td {
    border-bottom: 1px solid #dee2e6;
}

@media print {
    .page-break {
        display: block;
        break-after: auto;
        break-before: auto;
    }

    .student-print-row {
        break-inside: avoid;

    }

    .instructor-notes {
        break-inside: avoid;
        break-after: always;
    }

    .class-classList-feature .classlist-print-section h2,
    .class-classList-level-print h2 {
        font-size: 16px;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    .class-classList-level-print table {
        font-size: 10px;
    }

    .classlist-print-section .print-class-details h3 {
        font-size: 14px;
    }

    .class-print-section table {
        border-bottom: 1px solid #dee2e6;
    }

}

.class-classList-level-print .print-camp-details {
    display: flex;
    justify-content: space-between;
}

.print-class-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #80808069;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
}

.print-class-details p {
    margin: auto;
}

.print-level-header {
    background-color: #989898;
}


.intructor-checkIn-printout td {
    border: solid 1px #dee2e6;
    padding: 0.25rem;
}

.intructor-checkIn-printout {
    table-layout: fixed;
}

.class-print-section img {
    float: right;
    filter: grayscale(1);

}

.instructor-notes {
    display: flex;
    font-size: 12px;
}

.instructor-notes div {
    border: solid 1px #dee2e6;
    flex: 0 0 32.5%;
    padding-left: 5px;
}

.instructor-notes h3 {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 30px;
    font-family: 'Arial Narrow';
    color: grey;
    font-size: 18px;
}

.instructor-notes p {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 23px;
    font-family: 'Arial Narrow';
    font-size: 18px;
}

.instructor-notes ul {
    padding: 0 10px 10px 13px !important;
    margin: 0 !important;
}


.class-print-section table {
    font-size: 14px;
    table-layout: fixed;
}

.class-print-section table td,
.class-print-section table th {
    padding: 0.15rem;
}

td.checkin-date-td,
td.checkout-date-td {
    background-color: #dee2e6;
}

.class-classList-level-print table {
    font-size: 14px;
    table-layout: fixed;
}

.class-classList-level-print table th,
.class-classList-level-print table td {
    padding: 0px;
    margin: 0px;

}

.class-classList-level-print.class-checkin-print table th,
.class-classList-level-print.class-checkin-print table td {
    text-align: left;
}